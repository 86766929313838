<!--
// nuxt-ui/components/pages/region-home/CurrentSeasonSliderMatches.vue
-->
<script setup lang="ts">
import SliderMatches from "~/nuxt-ui/components/Shared/slider-matches/SliderMatches.vue";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import type { Team } from "~/src/team/domain/models/Team";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";
import SliderArrowsBehaviour from "~/nuxt-ui/components/Shared/slider-matches/SliderArrowsBehaviour.vue";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

type Props = {
  sectionTitle?: boolean;
  homeTitle?: boolean;
};

withDefaults(defineProps<Props>(), {
  sectionTitle: false,
  homeTitle: false,
});

const { getSeasonTurns } = useSeasons();
const { currentSeason, currentSeasonTeams } = useCompetitionStore();
const { teamArrToObjById } = useTeams();
const { isWorldCup } = useRegions();

const teamsObj = ref<Record<number, Team>>({});
const turns = currentSeason ? await getSeasonTurns(currentSeason.id) : [];

// create team Object with the team ID as key.
teamsObj.value = isWorldCup
  ? await teamArrToObjById(currentSeasonTeams, currentSeason?.id)
  : await teamArrToObjById(currentSeasonTeams);
</script>

<template>
  <div v-if="homeTitle">
    <h2 class="sr-only">{{ $t("matches.banner.title") }}</h2>
  </div>
  <div class="px-4 md:px-6 pb-8" v-if="Object.keys(turns)?.length && sectionTitle">
    <h2 class="kql-page-section-title">{{ $t("teams.nextMatches.title") }}</h2>
    <hr />
  </div>
  <slider-arrows-behaviour
    slider-container-id="matchesSlider"
    :conditionally-render-if="!!turns"
    button-left-id="buttonLeft"
    button-right-id="buttonRight"
    :button-scroll-distance="170"
  >
    <slider-matches v-if="Object.keys(turns)?.length" :phase-turns="turns" :teams="teamsObj"></slider-matches>
  </slider-arrows-behaviour>
</template>

<style scoped></style>
